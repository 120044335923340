// src/components/User/UserForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/userForm.css'

const UserForm = ({ onSubmit, user }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [brigada, setBrigada] = useState('');
    const [roles, setRoles] = useState([]);
    const [brigadas, setBrigadas] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (user) {
            setUsername(user.username);
            setEmail(user.email);
            setRole(user.role);
            setBrigada(user.brigada);
            setPassword(user.password)
        }

        fetchRoles();
        fetchBrigadas();
    }, [user]);

    const fetchRoles = async () => {
        try {
            const response = await axios.get('http://localhost:5000/api/roles');
            setRoles(response.data);
        } catch (error) {
            console.error('Error al cargar roles:', error);
        }
    };

    const fetchBrigadas = async () => {
        try {
            const response = await axios.get('http://localhost:5000/api/brigadas',{
                headers: {
                    Authorization: token ? `Bearer ${token}` : '', // Añade el token en el encabezado de Authorization
                }
            });
            setBrigadas(response.data);
        } catch (error) {
            console.error('Error al cargar brigadas:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ username, password, email, role, brigada });
        setUsername('');
        setPassword('');
        setEmail('');
        setRole('');
        setBrigada('');
    };

    return (
        <form onSubmit={handleSubmit} className="user-form">
            <input
                type="text"
                placeholder="Nombre de Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="user-input"
            />
            <input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="user-input"
            />
            <input
                type="email"
                placeholder="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="user-input"
            />
            <select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
                className="user-select"
            >
                <option value="">Seleccionar Rol</option>
                {roles.map((role) => (
                    <option key={role._id} value={role._id}>
                        {role.name}
                    </option>
                ))}
            </select>
            <select
                value={brigada}
                onChange={(e) => setBrigada(e.target.value)}
                required
                className="user-select"
            >
                <option value="">Seleccionar Brigada</option>
                {brigadas.map((brigada) => (
                    <option key={brigada._id} value={brigada._id}>
                        {brigada.name}
                    </option>
                ))}
            </select>
            <button type="submit" className="user-button">Guardar Usuario</button>
        </form>

    );
};

export default UserForm;
