// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import './css/Login.css'; // Importar el CSS

const Login = ({ onLogin }) => {
    const URL= process.env.REACT_APP_URL_LOCAL_HOST;
    const [email, setUsername] = useState('');
    const [password, setPassword] = useState('');


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(`${URL}/api/login`)
        try {
            const response = await axios.post(`${URL}/api/login`, { email, password }
            );
         

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('role', response.data.role);
            localStorage.setItem('userId', response.data.id);
            
            onLogin(response.data.role); 
        } catch (error) {
           
            alert('Error en el login');
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleSubmit}>
                <h2>Iniciar Sesión</h2>
                <input
                    type="text"
                    placeholder="Usuario"
                    value={email}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Iniciar Sesión</button>
            </form>
        </div>
    );
};

export default Login;
