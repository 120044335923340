// src/components/Sector/SectorList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SectorForm from './SectorForm';
import '../../css/sectorList.css';

const SectorList = () => {
    const [sectors, setSectors] = useState([]);
    const [filteredSectors, setFilteredSectors] = useState([]); 
    const [selectedSector, setSelectedSector] = useState(null);
    const [brigades, setBrigades] = useState([]);
    const [circunscripciones, setCircunscripciones] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCircunscripcion, setSelectedCircunscripcion] = useState(''); 
    const [searchText, setSearchText] = useState('');
    const token = localStorage.getItem('token');
    const URL= process.env.REACT_APP_URL_LOCAL_HOST;
    useEffect(() => {
        fetchSectors();
        fetchBrigades();
        fetchCircunscripciones();
    }, []);

    useEffect(() => {
        filterSectors();
    }, [sectors, selectedCircunscripcion, searchText]);

    const fetchSectors = async () => {
        const response = await axios.get(`${URL}/api/sectores`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });

        const sortedSectors = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setSectors(sortedSectors);
    };

    const fetchBrigades = async () => {
        const response = await axios.get(`${URL}/api/brigadas`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        setBrigades(response.data);
    };

    const fetchCircunscripciones = async () => {
        const response = await axios.get(`${URL}/api/circunscripciones`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        setCircunscripciones(response.data);
    };

    const filterSectors = () => {
        const filtered = sectors.filter(sector => {
            const matchesCircunscripcion = selectedCircunscripcion ? sector.circunscripcion === selectedCircunscripcion : true;
            console.log(sector)
            const matchesSearchText = sector.name.toLowerCase().includes(searchText.toLowerCase());
            return matchesCircunscripcion && matchesSearchText;
        });
        setFilteredSectors(filtered);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${URL}/api/sectores/${id}`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        fetchSectors();
    };

    const handleEdit = (sector) => {
        setSelectedSector(sector);
        setModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedSector(null);
        setModalOpen(true);
    };

    const handleSubmit = async (sector) => {
        if (selectedSector) {
            await axios.put(`${URL}/api/sectores/${selectedSector._id}`, sector, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
        } else {
            await axios.post(`${URL}/api/sectores`, sector, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
        }
        setModalOpen(false);
        setSelectedSector(null);
        fetchSectors();
    };

    return (
        <div>
            <h2>Sectores</h2>
            <button className='btn-agregar-sector' onClick={handleAdd}>Agregar Sector</button>

            {/* Filtros */}
            <div className="filters">
                <select value={selectedCircunscripcion} onChange={(e) => setSelectedCircunscripcion(e.target.value)}>
                    <option value="">Seleccionar Circunscripción</option>
                    {circunscripciones.map((circunscripcion) => (
                        <option key={circunscripcion._id} value={circunscripcion._id}>
                            {circunscripcion.name}
                        </option>
                    ))}
                </select>

                <input
                    type="text"
                    placeholder="Buscar Sector"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div>

            {isModalOpen && (
                <SectorForm
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    onSubmit={handleSubmit}
                    sector={selectedSector}
                    brigades={brigades}
                    circunscripciones={circunscripciones}
                />
            )}
            <ul className='lista-sector'>
                {filteredSectors.map((sector) => (
                    <li key={sector._id}>
                        {sector.name}
                        <button className='btn-editar' onClick={() => handleEdit(sector)}>Editar</button>
                        <button onClick={() => handleDelete(sector._id)}>Eliminar</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SectorList;
